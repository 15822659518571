<template>
  <div class="common-copyright-container">
    <h2>Terms of Use</h2>
    <div class="content">
      <p>
        Use of this website, www.inspiringhk.org and all pages in this domain,
        constitutes acceptance of the following terms and conditions. The
        Board of InspiringHK Sports Foundation (‘The Foundation’) reserves the
        right to seek all remedies available by law for any violation of these
        terms of use, including any violation of The Foundation’s rights in
        The Foundation name and emblem and its rights in connection with the
        information, artwork, text, video, audio, or pictures (together, the
        “content”) of the Foundation website.
      </p>
      <br>
      <p>
        <strong>The InspiringHK Sports Foundation Name and Emblem</strong>
      </p>
      <p>
        The InspiringHK Sports Foundation name and emblem are the exclusive
        property of The Foundation. Unauthorized use is prohibited. They may
        not be copied or reproduced in any way without the prior written
        permission of The Foundation. Requests for permission to use The
        Foundation’s name and emblem should be emailed to us at
        <a href="mailto:info@inspiringhk.org">info@inspiringhk.org</a>.
      </p>
      <br>
      <p><strong>The InspiringHK Sports Foundation Website</strong></p>
      <p>
        The InspiringHK Sports Foundation Website is for personal use and
        educational purposes only. Any other use, including reproduction or
        translation of anything more than a de minimis portion of the content
        of The Foundation website or any use other than for personal or
        educational purposes, requires the express prior written permission of
        The Foundation. Requests for permission should be emailed to us at
        <a href="mailto:info@inspiringhk.org">info@inspiringhk.org</a>
        specifying full details of the proposed use. All Content of The
        Foundation website is protected by law including, as applicable,
        Copyright Ordinance.
      </p>
      <br>
      <p>
        The use of particular designations of countries or territories does
        not imply any judgment by The Foundation as to the legal status of
        such countries or territories, of their authorities and institutions
        or of the delimitation of their boundaries. The mention of names of
        specific companies or products (whether or not indicated as
        registered) does not imply any intention to infringe proprietary
        rights, nor should it be construed as an endorsement or recommendation
        on the part of The Foundation.
      </p>
      <br>
      <p>
        The Foundation disclaims any liability or responsibility arising from
        the use of The Foundation website or the content of The Foundation
        website. The Foundation, members of their staff, and their
        contractors, shall not be liable for any financial or other
        consequences whatsoever arising from the use of the content of The
        Foundation website, including the inappropriate, improper, or
        fraudulent use of such content. No representations or warranties of
        any kind concerning The Foundation website are given, including
        responsibility for any infection by virus or any other contamination
        or by anything which has destructive properties.
      </p>
      <br>
      <p><strong>Other Websites</strong></p>
      <p>
        The Foundation website may be linked to other web sites that are not
        under The Foundation’s control. The Foundation provides these links
        merely as a convenience and the inclusion of such links does not imply
        an endorsement or approval by The Foundation of any website, product
        or service. The Foundation does not assume any responsibility or
        liability in respect of such web sites, including, for example,
        responsibility or liability for the accuracy or reliability of any
        information, data, opinions, advice or statements made on those
        websites.
      </p>
      <br>
      <p><strong>Linking to InspiringHK Sports Foundation</strong></p>
      <p>
        InspiringHK Sports Foundation welcomes links from sites that share our
        goals of engaging the underprivileged in sport and unleashing their
        human potential. By adding a link you are expressing your support for
        these goals. If you choose to link to The Foundation website, you must
        agree to all of the terms and conditions for linking set forth below.
        If you do not agree to abide by these terms and conditions, do not
        establish a link to The Foundation website. By establishing a link to
        The Foundation website, you will be deemed to have agreed to all of
        the terms and conditions set forth below.
      </p>
      <br>
      <p>
        If you wish to link from your website to any pages of our sites you
        may do so only on the basis that you link to, but do not replicate,
        the website page, and subject to the following conditions:
      </p>
      <br>
      <ul style="margin-left:1em;list-style: outside;">
        <li  v-for="(item, index) in list" :key="index">
          {{ item.txt }}
        </li>
      </ul>
      <br>
      <p>
        At any time and at our complete discretion we reserve the right to
        withdraw the permission to make website links to our sites in which
        case the site manager must agree to remove the link immediately.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { txt: 'The linking text must simply consist of the website address;' },
        {
          txt:
            'You do not remove, distort or otherwise alter the size or appearance of any logos on our sites;',
        },
        {
          txt:
            'You do not in any way imply that we are endorsing any products or services;',
        },
        {
          txt:
            'You do not misrepresent your relationship with us nor present any other false information about us;',
        },
        {
          txt:
            'You do not otherwise use any InspiringHK Sports Foundation’s trade mark displayed on our sites without our express written permission;',
        },
        { txt: 'You do not link from a website that is not owned by you; and' },
        {
          txt:
            'Your website does not contain content that is distasteful, pornographic, infringes any intellectual property rights or other rights of any other person or otherwise does not comply with all applicable laws and regulations.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.term-of-use {
  h2 {
    text-align: center;
    padding: 50px 0;
    color: #9c0;
    font-weight: bold;
    font-size: 30px;
  }
  .content {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    line-height: 30px;
    font-size: 14px;
    letter-spacing: 0;
    
  }
}
</style>
